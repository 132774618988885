.block-element{
    display: block;
}
.mixt-container{
    justify-content: space-between;
    background-color: #ffffff;
}
.logo-container-mid{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}
.logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar-container{
    display:flex;
    justify-content:space-around; 
    width: 100%;
    text-align:center;
}
.item-flow-reverse{
    flex-direction: row-reverse;
}
.item-flow{
    flex-direction: row;
}
.logo-text-color{
    color:#000000;
}