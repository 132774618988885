.topicbreakdown-filter-button {
    width: 50%;
    font-size: 12px;
    border-radius: 2rem;
    background: black;
    color: #fff;
    cursor: pointer;
    transition: transform 0.5s;
}

.topicbreakdown-filter-button:hover {
    transform: scale(1.1);
}

.topicbreakdown-filter-button:active{
    transform: scale(1);
}